import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createElement, PureComponent, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { PageWidth } from "../components/content-renderers/Prose";
import {
  methodsRoute,
  // contactRoute,
  // welcomeRoute,
  resumeRoute,
  welcomeRoute,
  workRoute
} from "../router/routes";

export class BottomNav extends PureComponent {
  private readonly linkItemStyle = {
    backgroundColor: "#fff",
    padding: "0 1rem",
    color: "inherit",
    textDecoration: "none"
  };

  private readonly iconLinkStyle = {
    margin: "0 1rem 0 0",
    color: "inherit",
    textDecoration: "none"
  };

  private readonly navLinksProps = {
    style: {
      listStyle: "none",
      padding: 0,
      margin: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      flexGrow: 1
    }
  };

  public render() {
    return createElement(
      "nav",
      {
        style: {
          paddingTop: "4rem",
          margin: "0 0 4rem",
          borderTop: "1px solid #2102"
        }
      },
      createElement(PageWidth, null, this.renderNavLinks())
    );
  }

  private renderIconLinks(): ReactNode {
    // prettier-ignore
    return createElement(
      "div",
      { style: this.linkItemStyle },
      this.renderIconLink("https://www.linkedin.com/in/ndchristie/", faLinkedin),
      this.renderIconLink("mailto:" + "nathan" + "@" + "ndchristie.com", faEnvelope),
      this.renderIconLink("https://twitter.com/ndchristie", faTwitter),
    );
  }

  private renderIconLink(href: string, icon: IconProp): ReactNode {
    return createElement(
      "a",
      {
        href,
        target: "_blank",
        style: this.iconLinkStyle
      },
      createElement(FontAwesomeIcon, { icon })
    );
  }

  private renderNavLinks(): ReactNode {
    return createElement(
      "ul",
      this.navLinksProps,
      createElement(
        "small",
        {
          style: {
            display: "block",
            lineHeight: 1.2,
            whiteSpace: "pre-wrap",
            margin: "0 1rem 1rem",
            color: "#777"
          }
        },
        `All content and source code \u00a9 2017\u2011${new Date().getFullYear()} N.D.Christie unless otherwise stated.\n`,
        `All images and artworks \u00a9 2014\u2011${new Date().getFullYear()} N.D.Christie unless otherwise stated.`
      ),
      this.renderIconLinks(),
      this.renderNavLink(welcomeRoute.path, "Home"),
      this.renderNavLink(workRoute.path, "Work"),
      this.renderNavLink(methodsRoute.path, "Methods"),
      // this.renderNavLink(contactRoute.path, "Contact"),
      this.renderNavLink(resumeRoute.path, "Resume")
    );
  }

  private renderNavLink(
    to: string | (string[] & string),
    ...linkContent: ReactNode[]
  ): ReactNode {
    return createElement(
      NavLink,
      {
        style: this.linkItemStyle,
        exact: true,
        to
      },
      ...linkContent
    );
  }
}

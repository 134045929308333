import { createElement, PureComponent, HTMLProps } from "react";

export interface HeadingProps extends HTMLProps<HTMLHeadingElement> {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
}

export class Heading extends PureComponent<HeadingProps> {
  public getEl() {
    switch (this.props.level) {
      case 1:
        return "h1";
      case 2:
        return "h2";
      case 3:
        return "h3";
      case 4:
        return "h4";
      case 5:
        return "h5";
      case 6:
        return "h6";
      default:
        return "p";
    }
  }
  public render() {
    return createElement(
      this.getEl(),
      {
        style: {
          fontFamily: "'abril fatface', serif",
          fontWeight: 400,
          lineHeight: "1",
          margin: "0 0 0.5em",
          ...this.props.style
        }
      },
      this.props.children
    );
  }
}

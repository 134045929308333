import { createElement } from "react";
import bg_jabbergen from "../../../../assets/bg_jabbergen.jpg";
import { PageWidth, Prose } from "../../../components/content-renderers/Prose";
import { Story } from "../../../components/content-renderers/Story";
import { ProjectBrief } from "./ProjectBrief";

export function JabberGenerator() {
  return createElement(
    Story,
    {
      headerContent: "Jabber Generator",
      bgColor: "#623",
      bgImage: bg_jabbergen
    },
    createElement(
      PageWidth,
      null,
      createElement(Prose, {
        blocks: [
          createElement(ProjectBrief, {
            roles: "Designer, Developer, Researcher",
            attribution: "An Open Source Project",
            techStack: "JavaScript (TypeScript)"
          }),
          [
            "The Jabber Generator is an open-source nonsense-word generator.",
            "It is primarily targeted at writers, world-builders and game designers."
          ],
          [
            "Jabber Generator was born of a desire for a more flexible, comprehensive word generator.",
            "Existing generators were either too simplistic, specific, or married to existing words.",
            "The Jabber Generator produces words from a stock of over 10,000 unique elements.",
            "Developers can further define their own elements, combination rules and post-generation transformations",
            "to tailor the generator to their liking."
          ],
          [
            "Jabber Generator remains in pre-alpha, but user input, mostly from online communities, has already been valuable."
          ]
        ]
      })
    )
  );
}

import { createElement } from "react";
import bg_p_marina from "../../../../assets/bg_p_marina.jpg";
import { Callout } from "../../../components/content-renderers/Callout";
import { PageWidth, Prose } from "../../../components/content-renderers/Prose";
import { Story } from "../../../components/content-renderers/Story";
import { Heading } from "../../../resume/Heading";
export function HumanCenteredDesign() {
  return createElement(
    Story,
    {
      headerContent: "Methods",
      bgColor: "#623",
      bgImage: bg_p_marina
      // headerBgBadge: badge_xp
    },
    createElement(
      PageWidth,
      null,
      createElement(Prose, {
        blocks: [
          createElement(Heading, { level: 3 }, "Human-Centered Design"),
          [
            "The true measurement of a product is its impact on the human experience.",
            "This starts with creating empathy with real people, understanding their hopes and their challenges.",
            "We follow through designs and implementations into new hopes and new challenges.",
            "From there we expand also to new audiences with their own experiences."
          ],
          createElement(
            Callout,
            null,
            "If necessity is the mother of invention, then an opportunity for innovation is no more elusive than an unmet need."
          ),
          createElement(Heading, { level: 3 }, "Discovery & Framing"),
          [
            "Human-centered design focusses on observing people",
            "through a combination of ",
            createElement(
              "a",
              {
                href: "https://en.wikipedia.org/wiki/Quantitative_research",
                target: "_blank"
              },
              "quantitative"
            ),
            " and ",
            createElement(
              "a",
              {
                href: "https://en.wikipedia.org/wiki/Qualitative_research",
                target: "_blank"
              },
              "qualitative"
            ),
            " research methods."
          ],
          [
            "The most intuitive workflow is general-to-specific.",
            "First we observe broad audiences to identify the core opportunity.",
            "Then we consult subject-matter experts to refine ideas with their knowledge of existing solutions and pain-points.",
            "Then we interview individual users with targeted questions and prototypes to guide development."
          ],
          [
            "No sooner than a product is released than can the process begin again.",
            "This time, data and feedback can come directly from the nascent userbase.",
            "The cycle can continue indefinitely, evolving over time just as people do."
          ]
        ]
      })
    )
  );
}

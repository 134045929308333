import { createElement, ReactNode } from "react";
export function Callout({ children }: { children?: ReactNode }) {
  return createElement(
    "div",
    {
      style: {
        backgroundColor: "#2101",
        padding: "1rem",
        position: "relative",
        margin: "2rem 0",
        fontStyle: "italic",
        border: "1px solid #2101",
        borderRadius: 2,
        textAlign: "center",
        whiteSpace: "pre-wrap"
      }
    },
    createElement("p", { style: { margin: 0 } }, children),
    createElement("div", {
      style: {
        height: "1rem",
        width: "1rem",
        position: "absolute",
        bottom: -1,
        right: -1,
        borderRadius: "2px 0 0 0",
        backgroundImage: "linear-gradient(to top left, white 50%, #2101 50%)"
      }
    })
  );
}

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createElement } from "react";
import { Heading } from "../../../resume/Heading";

export interface ContactIconProps {
  icon: IconProp;
  href: string;
  label: string;
  textContent: string;
}

export function ContactIconLarge({
  icon,
  href,
  label,
  textContent
}: ContactIconProps) {
  return createElement(
    "a",
    {
      href,
      target: "_blank",
      style: {
        textAlign: "center",
        width: "8rem",
        margin: "1rem",
        flexShrink: 1,
        textDecoration: "none",
        color: "inherit"
      }
    },
    createElement(FontAwesomeIcon, {
      icon,
      fixedWidth: true,
      size: "3x",
      style: { paddingBottom: "0.5rem" }
    }),
    createElement(Heading, { level: 4 }, label),
    createElement("small", null, textContent)
  );
}

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createElement, PureComponent, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import {
  methodsRoute,
  // contactRoute,
  // welcomeRoute,
  resumeRoute,
  welcomeRoute,
  workRoute
} from "../router/routes";
export class TopNav extends PureComponent {
  private readonly outerNavProps = {
    id: "main-nav",
    style: {
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 100,
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "stretch",
      justifyContent: "flex-end"
    }
  };

  private readonly innerNavProps = {
    style: {
      width: "100%",
      maxWidth: "66rem",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "stretch",
      justifyContent: "flex-end",
      flexGrow: 1
    }
  };

  private readonly navTitleLinkProps = {
    to: welcomeRoute.path,
    style: {
      flexGrow: 1,
      fontFamily: "abril fatface",
      fontWeight: 400,
      fontSize: "2rem",
      textDecoration: "none",
      color: "inherit"
    }
  };

  private readonly navTitleBarProps = {
    style: {
      display: "flex",
      alignItems: "center",
      padding: "0 0 0 1rem",
      flexGrow: 1e12,
      backgroundColor: "#fff"
    }
  };

  private readonly padderProps = {
    style: { backgroundColor: "#fff", flexGrow: 1 }
  };

  private readonly linkItemStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#fff",
    padding: "1rem",
    color: "inherit",
    textDecoration: "none"
  };

  private readonly activeLinkItemStyle = {
    color: "#fff",
    boxShadow: "inset 0 0.5rem #f68",
    backgroundColor: "transparent",
    backgroundImage:
      "linear-gradient(to bottom, #2108, #2016, #2012, transparent)"
  };

  private readonly iconLinkStyle = {
    margin: "0 0 0 1rem",
    color: "inherit",
    textDecoration: "none"
  };

  private readonly padder = createElement("div", this.padderProps);

  private readonly navLinksProps = {
    style: {
      listStyle: "none",
      padding: 0,
      margin: 0,
      display: "flex",
      alignItems: "stretch",
      justifyContent: "flex-end",
      flexGrow: 1
    }
  };
  private readonly titleBar = createElement(
    "div",
    this.navTitleBarProps,
    createElement(NavLink, this.navTitleLinkProps, "N.D.Christie"),
    this.renderIconLinks()
  );

  public render() {
    return createElement(
      "nav",
      this.outerNavProps,
      this.padder,
      createElement(
        "div",
        this.innerNavProps,
        this.titleBar,
        this.renderNavLinks()
      ),
      this.padder
    );
  }

  private renderIconLinks(): ReactNode {
    // prettier-ignore
    return createElement(
      "div",
      { style: this.linkItemStyle },
      this.renderIconLink("https://twitter.com/ndchristie", faTwitter),
      this.renderIconLink("mailto:" + "nathan" + "@" + "ndchristie.com", faEnvelope),
      this.renderIconLink("https://www.linkedin.com/in/ndchristie/", faLinkedin),
    );
  }

  private renderIconLink(href: string, icon: IconProp): ReactNode {
    return createElement(
      "a",
      {
        href,
        target: "_blank",
        style: this.iconLinkStyle
      },
      createElement(FontAwesomeIcon, { icon })
    );
  }

  private renderNavLinks(): ReactNode {
    return createElement(
      "ul",
      this.navLinksProps,
      this.padder,
      this.renderNavLink(workRoute.path, "Work"),
      this.renderNavLink(methodsRoute.path, "Methods"),
      // this.renderNavLink(contactRoute.path, "Contact"),
      this.renderNavLink(resumeRoute.path, "Resume")
    );
  }

  private renderNavLink(
    to: string | (string[] & string),
    linkText: string
  ): ReactNode {
    return createElement(
      NavLink,
      {
        style: this.linkItemStyle,
        activeStyle: this.activeLinkItemStyle,
        exact: true,
        to
      },
      linkText
    );
  }
}

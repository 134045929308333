import { createElement } from "react";
import bg_taprivals from "../../../../assets/bg_taprivals.jpg";
import { PageWidth, Prose } from "../../../components/content-renderers/Prose";
import { Story } from "../../../components/content-renderers/Story";
import { ProjectBrief } from "./ProjectBrief";
export function TapRivals() {
  return createElement(
    Story,
    {
      headerContent: "Tap Rivals",
      bgColor: "#623",
      bgImage: bg_taprivals
    },
    createElement(PageWidth, null, tapRivalsContent)
  );
}

const tapRivalsContent = createElement(Prose, {
  blocks: [
    createElement(ProjectBrief, {
      roles: "Designer, Developer, Researcher",
      attribution: "A Signal Zero Project ",
      techStack:
        "JavaScript (React, Node, Express, Mongo), SQL (Postgres), CSS (Sass)"
    }),
    [
      "In 2017, Signal Zero launched its second major platform: Tap Rivals.",
      "Tap Rivals is a “prize gaming” platform where mobile gamers monetize their skill.",
      "Tap Rivals is unique among such platforms, letting players compete against the community.",
      "This eliminates critical pain-points around limited or poorly-matched opponents.",
      "Tap Rivals is also different from online casinos because its titles are games of skill;",
      "i.e. the only significant factor is ability."
    ],

    [
      "In building Tap Rivals, we were able to synthesize much of what we learned from working on TokenWall.",
      "This allowed us to address usability, scalability and security concerns out-of-the-gate.",
      "As a result, we could focus on much finer points during user testing."
    ],

    [
      "We were further able to improve our workflow and user experience by leveraging an all-JavaScript stack,",
      "with React clients speaking to Node.js backends and Mongo databases.",
      "This was a huge improvement over the server-side rendering used for TokenWall.",
      "Due to attribution, mediation and authentication services, each TokenWall pageload could take several seconds —even after significant optimization. Pageloads for TapRivals are virtually instant, as these services only guard certain actions."
    ],

    [
      "Tap Rivals also features an intricate internal economy,",
      "which ensures a favorable and encouraging distribution of scores and prizes."
    ]
  ]
});

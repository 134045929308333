import { createElement } from "react";
import { PageWidth, Prose } from "../../../components/content-renderers/Prose";
import { AnchorLink } from "../../../components/content-renderers/AnchorLink";

export function SZ2020Update() {
  return createElement(
    "div",
    {
      style: {
        backgroundColor: "#2101",
        borderTop: "1px solid #2101",
        borderBottom: "1px solid #2101"
      }
    },

    createElement(
      PageWidth,
      {
        style: { padding: "2rem", textAlign: "center" }
      },
      createElement(Prose, {
        blocks: [
          [
            "Update January, 2020: After 6 years of navigating fraud and a constantly-changing technological landscape,",
            "Signal Zero has closed its doors.",
            "Friend and CEO Tobias Batton has put together a detailed postmortem in three parts, which you can find here:"
          ],
          createElement(
            "div",
            null,
            createElement(
              AnchorLink,
              {
                style: { margin: "0 1rem", color: "inherit" },
                href:
                  "https://medium.com/@tobiasbatton/the-story-of-signal-zero-part-i-7b1a3b5a2eca"
              },
              "Part 1"
            ),
            createElement(
              AnchorLink,
              {
                style: { margin: "0 1rem", color: "inherit" },
                href:
                  "https://medium.com/@tobiasbatton/the-story-of-signal-zero-part-ii-68565dbcfaab"
              },
              "Part 2"
            ),
            createElement(
              AnchorLink,
              {
                style: { margin: "0 1rem", color: "inherit" },
                href:
                  "https://medium.com/@tobiasbatton/the-story-of-signal-zero-part-iii-b001b12248fd"
              },
              "Part 3"
            )
          )
        ]
      })
    )
  );
}

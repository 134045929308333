import { RouteProps } from "react-router";
import { Methods } from "../content/articles/methods/methods";
import { Welcome } from "../content/articles/welcome/welcome";
import { Work } from "../content/articles/work/Work";
import { Resume } from "../resume/resume";

export const welcomeRoute: Readonly<RouteProps> & { path: string } = {
  path: "/",
  exact: true,
  component: Welcome
};

export const workRoute: Readonly<RouteProps> & { path: string } = {
  path: "/work",
  component: Work
};

export const methodsRoute: Readonly<RouteProps> & { path: string } = {
  path: "/methods",
  component: Methods
};

// export const contactRoute: Readonly<RouteProps> & { path: string } = {
//   path: "/contact"
// };

export const resumeRoute: Readonly<RouteProps> & { path: string } = {
  path: "/resume",
  exact: true,
  component: Resume
};

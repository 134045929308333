import { createElement } from "react";
import bg_tokenwall from "../../../../assets/bg_tokenwall.jpg";
import { PageWidth, Prose } from "../../../components/content-renderers/Prose";
import { Story } from "../../../components/content-renderers/Story";
import { ProjectBrief } from "./ProjectBrief";

const tokenwallContent = createElement(Prose, {
  blocks: [
    createElement(ProjectBrief, {
      roles: "Designer, Developer, Researcher",
      attribution: "A Signal Zero Project ",
      techStack:
        "JavaScript (JQuery, Angular), Ruby (Rails), SQL (Postgres), CSS (Sass)"
    }),
    "TokenWall is a mobile-optimized offerwall, a web-app in which users earn real money for installing web apps or completing surveys.",
    [
      "TokenWall was Signal Zero's first major offering, debuting at a time when mobile advertising was rapidly changing.",
      "We faced two significant, simultaneous challenges: capturing an audience and thwarting would-be attackers.",
      "We overcame these obstacles by making a tremendous investments in security and a transparent, “no bullshit” user experience.",
      "While other services forced users to suffer through innumerable redirects and poor completion rates,",
      "TokenWall strictly filtered its offers —at our own expense— to ensure a quality experience."
    ],
    [
      "Where other services fell victim to nefarious schemes,",
      "we partnered with groups like Distil Networks and MaxMind to bolster our own internal security systems.",
      "TokenWall eclipsed (and outlived) most of its direct competition, thanks to these two policies."
    ]
  ]
});

export function TokenWall() {
  return createElement(
    Story,
    {
      headerContent: "TokenWall",
      bgColor: "#623",
      bgImage: bg_tokenwall
    },
    createElement(PageWidth, null, tokenwallContent)
  );
}

import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faNpm } from "@fortawesome/free-brands-svg-icons/faNpm";
import { faSkype } from "@fortawesome/free-brands-svg-icons/faSkype";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons/faCaretRight";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createElement, Fragment } from "react";
import { Link } from "react-router-dom";
import bg_p_mclaughlin from "../../../../assets/bg_p_mclaughlin.jpg";
import bg_p_university from "../../../../assets/bg_p_university.jpg";
import { AnchorLink } from "../../../components/content-renderers/AnchorLink";
import { FloatingHeading } from "../../../components/content-renderers/FloatingHeading";
import { ParaBg } from "../../../components/content-renderers/ParaBg";
import { PageWidth, Prose } from "../../../components/content-renderers/Prose";
import { Story } from "../../../components/content-renderers/Story";
import { Heading } from "../../../resume/Heading";
import { methodsRoute, resumeRoute, workRoute } from "../../../router/routes";
import { ContactIconLarge } from "./ContactIconLarge";

export function Welcome() {
  return createElement(
    Fragment,
    null,
    createElement(
      Story,
      { headerContent: "Who I Am", bgColor: "#623", bgImage: bg_p_university },
      createElement(
        PageWidth,
        null,
        createElement(Prose, {
          style: { fontSize: "1.2rem" },
          blocks: [
            createElement(Heading, { level: 2 }, "I'm N.D.Christie."),
            [
              "I'm an experienced designer and developer,",
              "concentrated in web and mobile applications and games."
            ],
            [
              "I'm devoted to breaking down silos by",
              "promoting cross‑disciplinary communication and collaboration."
            ],
            [
              "I'm an Agile enthusiast and a",
              createElement(
                "a",
                {
                  target: "_blank",
                  href: "https://instagram.com/phoebe_the_shiba"
                },
                "dog lover"
              ),
              "."
            ],
            createElement("br"),
            [
              "Want to see what I've done?",
              createElement("br"),
              createElement(FontAwesomeIcon, { icon: faCaretRight }),
              " Check out my",
              createElement(Link, { to: workRoute.path }, "work"),
              "."
            ],
            [
              "Want to know how I operate?",
              createElement("br"),
              createElement(FontAwesomeIcon, { icon: faCaretRight }),
              " Check out my",
              createElement(Link, { to: methodsRoute.path }, "methods"),
              "."
            ],
            [
              "Want to work with me?",
              createElement("br"),
              createElement(FontAwesomeIcon, { icon: faCaretRight }),
              " Check out my",
              createElement(Link, { to: resumeRoute.path }, "resume"),
              "."
            ]
          ]
        })
      )
    ),
    createElement(
      "div",
      {
        style: {
          color: "#fff",
          position: "relative",
          overflow: "hidden",
          margin: "0 0 4rem",
          width: "100%"
        }
      },
      createElement(ParaBg, { bgImage: bg_p_mclaughlin, bgColor: "#623" }),
      createElement(FloatingHeading, {
        headerContent: "Connect",
        style: {
          backgroundImage:
            "linear-gradient(to bottom, #2105, #2104, #2101, transparent)"
        }
      }),
      createElement(
        PageWidth,
        { style: { position: "relative", padding: "0 0 2rem" } },
        createElement(Prose, {
          blocks: [
            "Feel free to reach out through any of the following services:"
          ]
        }),
        createElement(
          "div",
          {
            style: {
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              maxWidth: "40rem",
              margin: "0 auto"
            }
          },
          createElement(ContactIconLarge, {
            icon: faEnvelope,
            href: "mailto:" + "nathan" + "@" + "ndchristie.com",
            label: "Email",
            textContent: "nathan" + "@" + "ndchristie.com"
          }),
          createElement(ContactIconLarge, {
            icon: faLinkedin,
            href: "linkedin.com/in/nchristie",
            label: "LinkedIn",
            textContent: "ndchristie"
          }),
          createElement(ContactIconLarge, {
            icon: faGithub,
            href: "https://github.com/ndchristie",
            label: "Github",
            textContent: "ndchristie"
          }),
          createElement(ContactIconLarge, {
            icon: faNpm,
            href: "https://www.npmjs.com/~ndchristie",
            label: "NPM",
            textContent: "ndchristie"
          }),
          createElement(ContactIconLarge, {
            icon: faSkype,
            href: "skype:ndchristie?add",
            label: "Skype",
            textContent: "@ndchristie"
          }),
          createElement(ContactIconLarge, {
            icon: faInstagram,
            href: "https://www.instagram.com/ndchristie/",
            label: "Instagram",
            textContent: "@ndchristie"
          }),
          createElement(ContactIconLarge, {
            icon: faTwitter,
            href: "https://twitter.com/ndchristie",
            label: "Twitter",
            textContent: "@ndchristie"
          })
        )
      )
    ),
    createElement(
      PageWidth,
      { style: { marginBottom: "4rem" } },
      createElement(Prose, {
        blocks: [
          createElement(Heading, { level: 3 }, "Meet Up"),
          [
            "I'm happy to get to know other creative minds.",
            "If you have a regular meetup group or just want to talk shop,",
            "send me an invitation through one of the services above",
            "and I'll try to get back to you within a few business days.",
            "I currently live in the Greater New York Area."
          ],
          createElement(Heading, { level: 3 }, "Collaborate"),
          [
            "Serious offers or inquiries should email me directly at ",
            createElement(
              AnchorLink,
              { href: "mailto:" + "nathan" + "@" + "ndchristie.com" },
              "nathan@ndchristie.com"
            ),
            ".",
            "Even if I am not available for a particular project,",
            "I am happy to offer insights or referrals as I am able to."
          ]
        ]
      })
    )
  );
}

import { createElement, Fragment, PureComponent } from "react";
import { CrossDiscipline } from "./CrossDiscipline";
import { AgileDevelopment } from "./AgileDevelopment";
import { HumanCenteredDesign } from "./HumanCenteredDesign";

export function Methods() {
  return createElement(
    Fragment,
    null,
    createElement(HumanCenteredDesign, null),
    createElement(AgileDevelopment, null),
    createElement(CrossDiscipline, null)
  );
}

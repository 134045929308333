import { createElement, PureComponent } from "react";
import { HashRouter, Route } from "react-router-dom";
import {
  // contactRoute,
  methodsRoute,
  resumeRoute,
  welcomeRoute,
  workRoute
} from "../router/routes";
import { BottomNav } from "./BottomNav";
import { TopNav } from "./TopNav";

export class App extends PureComponent {
  public render() {
    return createElement(
      HashRouter,
      null,
      createElement(
        "div",
        null,
        createElement(TopNav, null),
        createElement(Route, welcomeRoute),
        createElement(Route, workRoute),
        createElement(Route, methodsRoute),
        // createElement(Route, contactRoute),
        createElement(Route, resumeRoute),
        createElement(BottomNav, null)
      )
    );
  }
}

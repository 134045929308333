import { createElement } from "react";
import bg_p_lafayette from "../../../../assets/bg_p_lafayette.jpg";
import { Callout } from "../../../components/content-renderers/Callout";
import { PageWidth, Prose } from "../../../components/content-renderers/Prose";
import { Story } from "../../../components/content-renderers/Story";
export function CrossDiscipline() {
  return createElement(
    Story,
    {
      headerContent: "Cross-Discipline Work",
      bgColor: "#623",
      bgImage: bg_p_lafayette
      // headerBgBadge: badge_xp
    },
    createElement(
      PageWidth,
      null,
      createElement(Prose, {
        blocks: [
          [
            "A significant obstacle to success is the silo mentality,",
            "whereby information fails to cross superficial boundaries,",
            "strangling trust and efficiency in the process.",
            "The most direct way to break down silos is communication between teams and departments."
          ],
          createElement(
            Callout,
            null,
            "For the good of the product and the people who build it,\n",
            "designers, developers, marketers, stakeholders,\n",
            "\u2014 anyone working towards a common goal \u2014\n",
            "should participate in a common discourse."
          ),
          [
            "This familiarity allows otherwise disparate and insular groups",
            "to operate with appreciation of each other’s talents."
          ],
          [
            "From this foundation, cross-training and collaboration arise naturally, as individuals are exposed to new ideas.",
            "Organizations can unity efforts, teams can exceed the sum of their parts,",
            "and bus factors improve through the exposure of situational knowledge that would otherwise be diffiult to share through formal channels."
          ]
        ]
      })
    )
  );
}

import { createElement } from "react";
import bg_bui from "../../../../assets/bg_bui.jpg";
import { PageWidth, Prose } from "../../../components/content-renderers/Prose";
import { Story } from "../../../components/content-renderers/Story";
import { ResumeList } from "../../../resume/resume-list";
import { ProjectBrief } from "./ProjectBrief";

export function BuiCss() {
  return createElement(
    Story,
    {
      headerContent: "Bui",
      bgColor: "#623",
      bgImage: bg_bui
    },
    createElement(
      PageWidth,
      null,
      createElement(Prose, {
        blocks: [
          createElement(ProjectBrief, {
            roles: "Designer, Developer, Researcher",
            attribution: "An Open Source Project",
            techStack: "CSS (SCSS, PostCSS), JavaScript"
          }),
          "Bui is an open-source css library based on three simple principles:",
          createElement(ResumeList, {
            content: [
              createElement(
                "span",
                null,
                "Design for ",
                createElement("strong", null, "user accessibility"),
                ", with colors and font choices optimized for WCAG2.0 compliance, as well as considerations for color-blindness and other common concerns."
              ),
              createElement(
                "span",
                null,
                "Design for ",
                createElement("strong", null, "developer interpretation"),
                ", balancing semantic and aesthetic concerns using relative language (hot, cool) rather than restrictive role-based naming (success, error) or fragile, presentational names (green, red)."
              ),
              createElement(
                "span",
                null,
                "Design for ",
                createElement("strong", null, "mobile first"),
                ", ensuring a full feature set for the majority of users, and treating larger devices as an opportunity to embellish."
              )
            ]
          }),
          [
            "Bui is ideal for sites requiring for simple layouts and templates.",
            "Until early 2020, this portfolio was built on Bui.",
            "The Jabber Generator also uses Bui for its demo pages."
          ]
        ]
      })
    )
  );
}

import { createElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons/faFilePdf";
import ResumePDF from "../../assets/NDChristie_Resume_2020b.pdf";

export function DownloadLink() {
  return createElement(
    "a",
    {
      href: ResumePDF,
      target: "_blank",
      style: {
        margin: "1rem ",
        padding: 0,
        border: "none",
        borderRadius: 2,
        backgroundColor: "#f68",
        color: "white",
        fontSize: "1rem",
        display: "flex",
        height: "3rem",
        justifyContent: "flex-start",
        alignItems: "stretch",
        textAlign: "center",
        textDecoration: "none",
        lineHeight: "1rem"
      }
    },
    createElement(
      "div",
      {
        style: {
          borderRight: "2px solid white",
          padding: "1rem 0",
          width: "3rem",
          margin: 0
        }
      },
      createElement(FontAwesomeIcon, {
        icon: faFilePdf,
        fixedWidth: true,
        style: {
          transform: "scale(1.5)"
        }
      })
    ),
    createElement(
      "span",
      {
        style: { padding: "1rem", margin: 0, flexGrow: 1 }
      },
      " Download as .PDF"
    )
  );
}

import { Component, createElement } from "react";
import { FloatingHeading, FloatingHeadingProps } from "./FloatingHeading";
import { ParaBg, ParaBgProps } from "./ParaBg";

export const lerp = (a: number, b: number, pct: number) =>
  a * pct + b * (1 - pct);

export interface ArticleHeaderProps extends FloatingHeadingProps, ParaBgProps {}

export class ArticleHeader extends Component<ArticleHeaderProps> {
  private readonly containerProps = {
    style: {
      height: "40vh",
      width: "100%",
      position: "relative",
      overflow: "hidden",
      margin: "0 0 2rem"
    }
  };
  public render() {
    return createElement(
      "div",
      this.containerProps,
      createElement(ParaBg, this.props),
      createElement(FloatingHeading, {
        headerContent: this.props.headerContent,
        style: {
          position: "absolute",
          bottom: 0,
          left: 0,
          backgroundImage:
            "linear-gradient(to top, #2105, #2104, #2101, transparent)"
        }
      })
    );
  }
}

import { createElement, Fragment } from "react";
import { Virtu } from "./Virtu";
import { TokenWall } from "./Tokenwall";
import { TapRivals } from "./TapRivals";
import { BuiCss } from "./BuiCss";
import { JabberGenerator } from "./JabberGenerator";
import { SZ2020Update } from "./SZ2020Update";
import { PriorTo2016 } from "./PriorTo2016";

export function Work() {
  return createElement(
    Fragment,
    null,
    createElement(Virtu),
    createElement(TapRivals),
    createElement(TokenWall),
    createElement(SZ2020Update),
    createElement(BuiCss),
    createElement(JabberGenerator),
    createElement(PriorTo2016)
  );
}

import { createElement, ReactNode } from "react";
import { Heading } from "../../../resume/Heading";
export function ProjectBrief(props: {
  roles: ReactNode;
  attribution?: ReactNode;
  techStack: ReactNode;
}) {
  return createElement(
    "div",
    null,
    createElement(Heading, { level: 4 }, props.roles),
    createElement(
      "div",
      { style: { lineHeight: 1.2, whiteSpace: "pre-wrap", color: "#777" } },
      createElement(
        "small",
        null,
        props.attribution ? props.attribution + "\n" : null,
        props.techStack
      )
    )
  );
}

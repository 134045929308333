import { createElement } from "react";
import * as ReactDOM from "react-dom";
import "../assets/android-chrome-192x192.png";
import "../assets/android-chrome-512x512.png";
import "../assets/apple-touch-icon.png";
import "../assets/favicon-16x16.png";
import "../assets/favicon-32x32.png";
import "../assets/favicon.ico";
import { App } from "./app/App";

ReactDOM.render(createElement(App), document.getElementById("app"));

import { PureComponent, createElement } from "react";
import { AnchorLink } from "../components/content-renderers/AnchorLink";

export interface ExperienceProps {
  groupName: string;
  groupHref?: string;
  roleName: string;
  startDate: Date;
  endDate?: Date;
}

export class ResumeExperience extends PureComponent<ExperienceProps> {
  public readonly dateProps: Intl.DateTimeFormatOptions = {
    month: "short",
    year: "numeric"
  };

  public render() {
    return createElement(
      "div",
      null,
      createElement("h4", { style: { margin: 0 } }, this.props.roleName),
      createElement(
        "h5",
        { style: { margin: 0 } },
        this.renderGroup(),
        " | ",
        this.renderDate(this.props.startDate),
        " - ",
        this.renderDate(this.props.endDate, "Present")
      ),
      this.props.children
    );
  }

  private renderDate(date?: Date, fallback?: string) {
    return date ? date.toLocaleDateString(undefined, this.dateProps) : fallback;
  }

  private renderGroup() {
    return this.props.groupHref
      ? createElement(
          AnchorLink,
          { href: this.props.groupHref },
          this.props.groupName
        )
      : this.props.groupName;
  }
}

import { faCaretRight } from "@fortawesome/free-solid-svg-icons/faCaretRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createElement, PureComponent, ReactNode } from "react";
export interface ResumeListProps {
  content?: Iterable<ReactNode>;
}
export class ResumeList extends PureComponent<ResumeListProps> {
  public render() {
    return createElement(
      "ul",
      {
        style: {
          fontSize: "1rem",
          listStyle: "none",
          paddingLeft: "0",
          marginTop: 0,
          color: "#777" // lucky :)
        }
      },
      ...this.renderItems()
    );
  }

  public *renderItems() {
    if (!this.props.content) return;
    for (const item of this.props.content) {
      yield createElement(
        "li",
        {
          style: {}
        },
        createElement(FontAwesomeIcon, { icon: faCaretRight }),
        "\u00a0",
        this.renderItem(item)
      );
    }
  }

  public renderItem(item: ReactNode) {
    if (Array.isArray(item)) {
      return item.join(" | ");
    }
    return item;
  }
}

import { createElement, HTMLProps, PureComponent, ReactNode } from "react";

export interface FloatingHeadingProps extends HTMLProps<HTMLHeadingElement> {
  headerContent: ReactNode;
}

export class FloatingHeading extends PureComponent<FloatingHeadingProps> {
  private readonly headerBarStyle = {
    margin: 0,
    position: "relative",
    width: "100%",
    fontFamily: "abril fatface",
    fontWeight: 400,
    color: "#fff"
  };
  private readonly headerLeftBarProps = {
    style: {
      width: "100vw",
      height: 2,
      position: "absolute",
      bottom: "calc(2.5rem - 2px)",
      left: "-100vw",
      backgroundColor: "#fff",
      display: "inline-block"
    }
  };
  private readonly headerRightBarProps = {
    style: {
      width: "100vw",
      height: 2,
      position: "relative",
      top: "-0.5rem",
      left: "1rem",
      backgroundColor: "#fff",
      display: "inline-block"
    }
  };
  private readonly headerContentProps = {
    style: {
      margin: 0,
      padding: "1rem",
      whiteSpace: "nowrap",
      position: "relative",
      display: "inline-block"
    }
  };
  private readonly headerbarInnerProps = {
    style: { maxWidth: "66rem", margin: "auto", position: "relative" }
  };
  public render() {
    return createElement(
      "h1",
      { style: { ...this.headerBarStyle, ...this.props.style } },
      createElement(
        "div",
        this.headerbarInnerProps,
        createElement("div", this.headerLeftBarProps),
        createElement(
          "span",
          this.headerContentProps,
          this.props.headerContent,
          createElement("div", this.headerRightBarProps)
        )
      )
    );
  }
}

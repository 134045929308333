import { createElement } from "react";
import { PageWidth, Prose } from "../../../components/content-renderers/Prose";
import { AnchorLink } from "../../../components/content-renderers/AnchorLink";

export function PriorTo2016() {
  return createElement(
    "div",
    {
      style: {
        backgroundColor: "#2101",
        borderTop: "1px solid #2101"
      }
    },

    createElement(
      PageWidth,
      { style: { padding: "2rem", textAlign: "center" } },
      createElement(Prose, {
        blocks: [
          [
            "For work done prior to 2016, please reach out to me at ",
            createElement(
              AnchorLink,
              { href: "mailto:" + "nathan" + "@" + "ndchristie.com" },
              "nathan" + "@" + "ndchristie.com"
            ),
            " or visit my ",
            createElement(
              AnchorLink,
              { href: "https://www.linkedin.com/in/ndchristie/" },
              "LinkedIn Profile"
            ),
            "."
          ]
        ]
      })
    )
  );
}

import { createElement, PureComponent } from "react";
import { ArticleHeader, ArticleHeaderProps } from "./ArticleHeader";

export class Story extends PureComponent<ArticleHeaderProps> {
  public render() {
    return createElement(
      "article",
      { style: { margin: "0 0 4rem" } },
      createElement(ArticleHeader, {
        headerContent: this.props.headerContent,
        bgBadge: this.props.bgBadge,
        bgColor: this.props.bgColor,
        bgImage: this.props.bgImage
      }),
      this.props.children
    );
  }
}

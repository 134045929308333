import { createElement, HTMLProps } from "react";

export const anchorLinkStyle = {
  display: "inline-block",
  fontFamily: "Fira Sans Condensed",
  fontWeight: 600
};

export function AnchorLink(props: HTMLProps<HTMLAnchorElement>) {
  return createElement("a", {
    target: "_blank",
    ...props,
    style: { ...anchorLinkStyle, ...props.style }
  });
}

import { createElement } from "react";
import badge_xp from "../../../../assets/badge_xp.png";
import bg_p_glory from "../../../../assets/bg_p_glory.jpg";
import { Callout } from "../../../components/content-renderers/Callout";
import { PageWidth, Prose } from "../../../components/content-renderers/Prose";
import { Story } from "../../../components/content-renderers/Story";
import { ResumeList } from "../../../resume/resume-list";
export function AgileDevelopment() {
  return createElement(
    Story,
    {
      headerContent: "Agile Development",
      bgColor: "#623",
      bgImage: bg_p_glory,
      bgBadge: badge_xp
    },
    createElement(
      PageWidth,
      null,
      createElement(Prose, {
        blocks: [
          [
            "Agile research and development involves reducing ideas to the smallest meaningful challenges.",
            "Solutions to these challenges are prototyped.",
            "Promising solutions are iterated upon."
          ],
          [
            createElement(
              Callout,
              null,
              "If traditional development is a waterfall, Agile is white-water rapids."
            )
          ],
          [
            "We often joke that, given the goals of being fast, cheap, or effective, we can only meet one or two.",
            "Agile Research & Development rejects this limitation outright."
          ],
          [
            createElement(ResumeList, {
              content: [
                createElement(
                  "span",
                  null,
                  createElement("strong", null, "Fast. "),
                  "Prototypes can be tested in a matter of days or hours. Only as much research needs to be done up-front to identify what to prototype."
                ),
                createElement(
                  "span",
                  null,
                  createElement("strong", null, "Cheap. "),
                  "Updates are minimize investment. If problems are encountered early-on, we can correct for them long before budgets are spent."
                ),
                createElement(
                  "span",
                  null,
                  createElement("strong", null, "Effective. "),
                  "Constant feedback helps identify opportunities and validates solutions that might not even be dreamed of without user input."
                )
              ]
            })
          ],
          [
            "Together with a human-centered approach, Agile research and devleopment means we can swiftly",
            "and continuously refine products that solve real problems for real people through a tight, iterative approach."
          ]
        ]
      })
    )
  );
}

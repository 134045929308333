import {
  Component,
  createElement,
  Fragment,
  HTMLProps,
  ReactNode
} from "react";
// import { linkContent } from "../../nav/nav.scss";

export const paragraphStyle = { padding: "0 1rem", margin: "1rem 0" };

export class Prose extends Component<
  Pick<HTMLProps<HTMLDivElement>, "style"> & {
    blocks: ReactNode[];
  }
> {
  render() {
    return createElement(Fragment, null, ...this.getContent());
  }
  *getContent() {
    for (const block of this.props.blocks) {
      yield createElement(
        "div",
        { style: { ...paragraphStyle, ...this.props.style } },
        ...this.getParagraphContent(block)
      );
    }
  }
  *getParagraphContent(block: ReactNode) {
    if (Array.isArray(block)) {
      for (let chunk of block) {
        if (typeof chunk === "string") chunk = chunk.replace(/-/g, "\u2011");
        yield chunk;
        if (typeof chunk !== "object") yield " ";
      }
    } else {
      yield block;
    }
  }
}

export const PageWidth = (props: HTMLProps<HTMLDivElement>) => {
  return createElement("div", {
    ...props,
    style: { maxWidth: "66rem", margin: "auto", ...props.style }
  });
};

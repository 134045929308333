import { createElement, Fragment, PureComponent } from "react";
import bg_p_ptisabel from "../../assets/bg_p_ptisabel.jpg";
import { PageWidth, Prose } from "../components/content-renderers/Prose";
import { Story } from "../components/content-renderers/Story";
import { DownloadLink } from "./DownloadLink";
import { Heading } from "./Heading";
import { ResumeExperience } from "./resume-experience";
import { ResumeList } from "./resume-list";

/** This class is effectively a document which delegates most repetitive styling to subcomponents */
export class Resume extends PureComponent {
  private readonly creative = createElement(
    "div",
    null,
    createElement(Heading, { level: 3 }, "Creative Software"),
    createElement(ResumeList, {
      content: [
        ["Adobe Illustrator", "Inkscape"],
        ["Adobe Photoshop", "GIMP"],
        ["Adobe InDesign", "Publisher"],
        "Adobe After Effects",
        ["Sketch", "Figma", "Axure RP", "Balsamiq"],
        ["XMind", "FreeMind"]
      ]
    })
  );

  private readonly tools = createElement(
    "div",
    null,
    createElement(Heading, { level: 3 }, "Tools \u0026 Frameworks"),
    createElement(ResumeList, {
      content: [
        ["Node.js", "Ruby on Rails"],
        ["Express", "Netty", "Vert.x"],
        ["Angular", "React", "Vue"],
        ["Gulp", "Babel", "Webpack"],
        ["MongoDB", "Mongoose"],
        ["Karma", "Mocha", "Jasmine", "Jest"],
        ["TeamCity", "Travis", "Travis", "Circle CI"],
        ["Postgres", "Vertica", "Oracle Database"],
        ["Unity", "Godot", "Atomic", "Phaser"],
        ["Git", "Hg", "SVN"]
      ]
    })
  );

  private readonly languages = createElement(
    "div",
    null,
    createElement(Heading, { level: 3 }, "Programming Languages"),
    createElement(ResumeList, {
      content: [
        ["JavaScript", "TypeScript"],
        ["ActionScript", "HTML5 Canvas"],
        // ["Python 2", "3"],
        ["CSS", "SCSS", "Stylus"],
        "Ruby",
        "Java",
        "C#",
        "Objective-C",
        "SQL"
      ]
    })
  );

  private readonly skills = createElement(
    "div",
    null,
    createElement(Heading, { level: 3 }, "Skills"),
    createElement(ResumeList, {
      content: [
        "Web \u0026 Mobile Design \u0026 Development",
        "Game Design \u0026 Development",
        "Web Server Architecture",
        "Product Management",
        "Typographic Design",
        "User Experience Research",
        "Information Architecture",
        "Graphics \u0026 Animation"
      ]
    })
  );

  private readonly education = createElement(
    "div",
    null,
    createElement(Heading, { level: 3 }, "Education"),
    /** Parsons */
    createElement(ResumeExperience, {
      groupName: "Parsons the New School for Design",
      groupHref: "https://www.newschool.edu/parsons/",
      roleName: "Bachelor of Fine Arts | Fine Art",
      startDate: new Date("Jan 10, 2010"),
      endDate: new Date("April 1, 2011")
    }),
    /** Lang */
    createElement(ResumeExperience, {
      groupName: "Eugene Lang College of Liberal Arts",
      groupHref: "https://www.newschool.edu/lang/",
      roleName: "Bachelor of Arts | Literature",
      startDate: new Date("Jan 10, 2010"),
      endDate: new Date("April 1, 2011")
    })
  );

  private readonly evanetiks = createElement(
    ResumeExperience,
    {
      groupName: "Evanetiks",
      roleName: "Visual Lead",
      startDate: new Date("Jan 10, 2010"),
      endDate: new Date("April 1, 2011")
    },
    createElement(ResumeList, {
      content: [
        "Designed and provided direction for animations and interfaces for mobile games",
        "Designed and provided direction for visual identity and branding"
      ]
    })
  );

  private readonly pentUp = createElement(
    ResumeExperience,
    {
      groupName: "PentUp Games",
      roleName: "Visual Lead",
      startDate: new Date("April 1, 2011"),
      endDate: new Date("Nov 10, 2012")
    },
    createElement(ResumeList, {
      content: [
        "Designed and provided direction for animations and interfaces for mobile games",
        "Designed and provided direction for visual identity and branding"
      ]
    })
  );

  private readonly gameloft = createElement(
    ResumeExperience,
    {
      groupName: "Gameloft SE",
      groupHref: "https://www.gameloft.com/en/",
      roleName: "UI Designer",
      startDate: new Date("Jan 3, 2013"),
      endDate: new Date("May 5, 2013")
    },
    createElement(ResumeList, {
      content: [
        "Worked with third-party intellectual properties, including Marvel and Pixar",
        "Scripted animations and pipeline automations",
        "Designed animations, interfaces and typefaces for mobile games"
      ]
    })
  );

  private readonly signalZero = createElement(
    ResumeExperience,
    {
      groupName: "Signal Zero, Inc.",
      groupHref: "https://www.signalzero.com/",
      roleName: "VP Design",
      startDate: new Date("June 1, 2013"),
      endDate: new Date("December 1, 2017")
    },
    createElement(
      "p",
      null,
      [
        "Signal Zero build solutions for analytics, attribution, loss-prevention, and mediation",
        "to improve engagement, retention, and user experience",
        "in original and partnered games and applications."
      ].join(" ")
    ),
    createElement(ResumeList, {
      content: [
        "Performed quantitative & qualitative research, from data analysis to user testing",
        "Designed, directed and assisted development of web & mobile games & apps",
        "Trained in Agile (Extreme Programming) at Pivotal Labs and General Assembly",
        "Managed design reviews, product backlogs and iterative planning meetings"
      ]
    })
  );

  private readonly virtu = createElement(
    ResumeExperience,
    {
      groupName: "Virtu Financial, Inc.",
      groupHref: "https://www.virtu.com/",
      roleName: "Developer",
      startDate: new Date("Feb 27 2018")
    },
    createElement(
      "p",
      null,
      [
        "Virtu leverages cutting-edge technology to provide liquidity to global markets",
        "as well as innovative, transparent, and efficient trading solutions to clients."
      ].join(" ")
    ),
    createElement(ResumeList, {
      content: [
        "Designed & built server-side components for composing scalable micro-services",
        "Worked directly with traders & analysts to frame business goals as deliverables",
        "Migrated legacy GUI’s to modern web technology (React, Express, TypeScript)",
        "Collaborated with UX designers to implement reusable front-end components",
        "Ported propriety protocols to TypeScript, vastly improving front-end performance"
      ]
    })
  );

  private readonly experience = createElement(
    "div",
    null,
    createElement(Heading, { level: 3 }, "Experience"),
    this.virtu,
    this.signalZero,
    this.gameloft,
    this.pentUp,
    this.evanetiks
  );

  private readonly content = createElement(
    "div",
    null,
    createElement(
      PageWidth,
      null,
      createElement(
        "div",
        { style: { maxWidth: "24rem" } },
        createElement(DownloadLink)
      ),

      createElement(
        "div",
        {
          style: {
            display: "flex",
            flexWrap: "wrap",
            fontSize: "1.2rem",
            margin: "1rem"
          }
        },
        createElement(
          "div",
          { style: { flexBasis: "36rem", flexGrow: 1, marginRight: "1rem" } },
          this.experience,
          this.education
        ),
        createElement(
          "div",
          { style: { flexShrink: 0 } },
          this.skills,
          this.languages,
          this.tools,
          this.creative
        )
      )
    )
  );

  public render() {
    return createElement(
      Fragment,
      null,
      createElement(
        Story,
        {
          headerContent: "Resume",
          bgColor: "#634",
          bgImage: bg_p_ptisabel
        },
        this.content
      ),
      createElement(
        "div",
        {
          style: {
            color: "#777",
            backgroundColor: "#2101",
            borderTop: "1px solid #2101"
          }
        },
        createElement(
          PageWidth,
          { style: { padding: "2rem", textAlign: "center" } },
          createElement(Prose, {
            blocks: ["References are available upon request."]
          })
        )
      )
    );
  }
}

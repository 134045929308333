import { createElement, createRef, PureComponent } from "react";
import { lerp } from "./ArticleHeader";

export interface ParaBgProps {
  bgBadge?: string;
  bgColor: string;
  bgImage: string;
}

export class ParaBg extends PureComponent<ParaBgProps> {
  private req: number = -1;
  private readonly paraBg = createRef<HTMLDivElement>();

  private readonly bgImageStyle = {
    position: "absolute",
    transformOrigin: "0 0",
    top: "50%",
    left: "50%"
  };
  private readonly badgeStyle = {
    position: "absolute",
    transformOrigin: "1 1",
    width: "50vw",
    minWidth: "33vh",
    right: "-1rem",
    top: "50%"
  };

  public componentDidMount() {
    this.doFrameUpdate();
  }

  public componentWillUnmount() {
    cancelAnimationFrame(this.req);
  }

  public render() {
    return createElement(
      "div",
      {
        ref: this.paraBg,
        style: {
          position: "absolute",
          bottom: 0,
          left: 0,
          height: "100%",
          width: "100%",
          backgroundColor: this.props.bgColor
        }
      },
      createElement("img", {
        src: this.props.bgImage,
        style: this.bgImageStyle
      }),
      this.props.bgBadge
        ? createElement("img", {
            src: this.props.bgBadge,
            style: this.badgeStyle
          })
        : null
    );
  }
  private readonly doFrameUpdate = () => {
    const container = this.paraBg.current;
    if (container) {
      const outerRect = container.getBoundingClientRect();
      const viewportHeight = document.documentElement.clientHeight;

      const inWindow =
        (outerRect.top + outerRect.height) /
        (viewportHeight + outerRect.height);

      if (inWindow > -1 && inWindow < 1) {
        const bgImage = container.firstElementChild as HTMLImageElement;

        // get "cover" scale based on image dimension
        const scale = Math.max(
          (outerRect.height / bgImage.height) * 1.2,
          (viewportHeight / bgImage.height) * 0.6,
          outerRect.width / bgImage.width
        );

        // prettier-ignore
        const bgTransform = `scale(${(Math.ceil(scale * 100)/100).toFixed(4)}) translate(-50%, ${lerp(-70, -30, inWindow).toFixed(4)}%)`;
        bgImage.style.transform = bgImage.style.webkitTransform = bgTransform;

        // do badge if we have one
        const badge = bgImage.nextElementSibling as HTMLImageElement;
        if (badge) {
          // prettier-ignore
          const badgeTransform = `translateY(${lerp(-75, -35, inWindow).toFixed(4)}%)`;
          badge.style.transform = badge.style.webkitTransform = badgeTransform;
        }
      }
    }
    this.req = requestAnimationFrame(this.doFrameUpdate);
  };
}

import { createElement } from "react";
import badge_Virtu from "../../../../assets/badge_virtu_slashes.png";
import bg_Virtu from "../../../../assets/bg_virtu_blue.jpg";
import { Story } from "../../../components/content-renderers/Story";
import { Prose, PageWidth } from "../../../components/content-renderers/Prose";
import { Callout } from "../../../components/content-renderers/Callout";
import { AnchorLink } from "../../../components/content-renderers/AnchorLink";
import { resumeRoute } from "../../../router/routes";
import { Link } from "react-router-dom";
import { ProjectBrief } from "./ProjectBrief";

const virtuLink = createElement(
  "a",
  { href: "https://www.virtu.com/", target: "blank" },
  "the official website"
);

export const Virtu = () =>
  createElement(
    Story,
    {
      headerContent: "Recent Work: Virtu",
      bgColor: `#234`,
      bgImage: bg_Virtu,
      bgBadge: badge_Virtu
    },
    createElement(
      PageWidth,
      null,
      createElement(Prose, {
        blocks: [
          createElement(ProjectBrief, {
            roles: "Developer",
            techStack:
              "JavaScript (React, Node, Express, TypeScript), Java, CSS (Stylus)"
          }),
          [
            "Virtu is a leader in Financial Services,",
            "offering an array of client-facing services and solutions,",
            "backed by the same technology that supports their own proprietary trading arm.",
            "My department, Client Execution Services, provides the 'glue'",
            "between our rich front-end experience",
            "and high-performance trading algos."
          ],
          createElement(
            Callout,
            null,
            "Every connection requires a delicate balance between efficiency, maintainability, and security."
          ),
          [
            "This is by no means a simple task, nor were the systems we connect built in a day.",
            "They represent thousands of man-years contributed over the past two decades,",
            "and each comes with their own strengths and specificities.",
            "It is a dedication to quality, end-to-end, front-to-back,",
            "that allows us to deliver blazing fast, secure and transparent service to our clients."
          ],
          [
            "While I am not at liberty to disclose further details regarding our solutions,",
            "You may find a summary of my role at Virtu in my",
            createElement(Link, { to: resumeRoute.path }, "resume"), // dynamic to avoid issues with static import order
            ", or read more about Virtu's offerings at",
            virtuLink,
            "."
          ]
        ]
      })
    )
  );
